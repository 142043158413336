<template>
<div class="">

  <div class="" v-html="HTMLcontent">
  </div>

</div>
</template>

<script>
import apiClient from "@/services/API";

export default {
  name: 'app',
    data () {
      return {
            HTMLcontent: ``
      }
    },
    methods: {
      async fetchData() {
        try {
          const url = '/support'
          const response = await apiClient.get(url)
          const results = response.data
          //console.log(results)
          this.HTMLcontent = results
          /*
          this.posts = results.map(post => ({
            title: post.title,
            abstract: post.abstract,
            url: post.url,
            thumbnail: this.extractImage(post).url,
            caption: this.extractImage(post).caption,
            byline: post.byline,
            published_date: post.published_date,
          }))
          */
        } catch (error) {
          if (error.response) {
            // client received an error response (5xx, 4xx)
            console.log("Server Error:", error)
          } else if (error.request) {
            // client never received a response, or request never left
            console.log("Network Error:", error)
          } else {
            console.log("Client Error:", error)
          }
        }
      }
    },
    mounted() {
      this.fetchData()
    }
}
</script>